<template>
  <base-table-layout
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
  >
    <!-- toolbar -->
    <!--    <template v-slot:toolbar>-->
    <!--      :icon-name="tableIcon"-->
    <!--      <table-toolbar>-->
    <!--        {{ tableTitle }}-->
    <!--        <template v-slot:notifications>-->
    <!--          <notification-menu-->
    <!--            :notifications="notifications"-->
    <!--            @selectedNotification="onSelectedNotification"-->
    <!--          >-->
    <!--          </notification-menu>-->
    <!--        </template>-->
    <!--      </table-toolbar>-->
    <!--    </template>-->

    <!-- DataTable search -->
    <template v-slot:search v-if="visibleSearch">
      <search-text-field
        v-model="search"
        v-show="visibleSearch"
      ></search-text-field>
    </template>

    <!-- data Table -->
    <template v-slot:dataTable>
      <v-data-table
        v-model="selectedItems"
        :show-select="showSelectCheckBoxes"
        :single-select="singleSelect"
        :item-key="itemKey"
        :headers="listHeaders"
        :items="items"
        :items-per-page="itemsPerPage"
        @click:row="showItemDetails"
        :multi-sort="true"
        :search="search"
        :loading="isLoading"
        :loading-text="loadingText"
        :item-class="rowBackgroundClassName"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <!-- Hide Toolbar -->
          <layout-data-table-toolbar v-show="false">
            <template v-slot:commands>
              <!-- Show Record Details Dialog -->
              <record-details-dialog
                :record="editedItem"
                :category="editedItemCategory"
                :visible="visibleRecordDetailsDialog"
                @close="closeRecordDetailsDialog"
              >
              </record-details-dialog>

              <!-- Show Download File Dialog -->
              <download-file-dialog
                :record="downloadFileRecord"
                :visible="visibleDownloadFileDialog"
                :download-mode="downloadMode.view"
                :is-email="isEmail"
                @close="closeDownloadFileDialog"
              >
              </download-file-dialog>

              <!-- Show Print Options Dialog -->
              <print-file-dialog
                :record="downloadFileRecord"
                :visible="visiblePrintFileDialog"
                :download-mode="downloadMode.view"
                @close="closePrintFileDialog"
              >
              </print-file-dialog>

              <!-- Document Editor Dialog -->
              <default-pdf-viewer-dialog
                :visible="visibleDefaultPdfViewerDialog"
                :record="viewedRecord"
                :is-view-only="true"
                @onCheckout="recordCheckOut(true)"
                @close="closeDefaultPdfViewerDialog"
              ></default-pdf-viewer-dialog>
            </template>
          </layout-data-table-toolbar>
        </template>

        <!-- Link to document viewer -->
        <template v-slot:item.name="{ item }">
          <document-link
            :record="item"
            v-on:click="showDocumentEditorDialog"
            :use-default-pdf-viewer="true"
            @openDefaultPdfViewer="openDefaultPdfViewerDialog(item)"
            :is-history="true"
          ></document-link>
        </template>

        <!-- record pages -->
        <template v-slot:item.pageCount="{ item }">
          <v-chip v-if="item.isSingleFile">
            {{ item.pageCount }}
          </v-chip>
        </template>

        <!-- record type -->
        <template v-slot:item.recordType="{ item }">
          <v-icon :color="recordIconColorClass(item)"
            >{{ recordIcon(item) }}
          </v-icon>
        </template>

        <!-- Actions -->
        <template v-slot:item.actions="{ item }">
          <base-drop-down-menu-button top ref="refDropDownMenuActions">
            <template v-slot:iconName></template>
            <template v-slot:iconMenuDownName>{{
              iconDotsHorizontal
            }}</template>
            <template v-slot:commands>
              <!-- Show Print File Dialog -->
              <template v-if="visibleCommandPrint(item)">
                <base-menu-item @click="showPrintFileDialog(item)">
                  <template v-slot:iconName>
                    {{ commandPrintIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipPrintLabel(item) }}
                    </div>
                  </template>
                  {{ commandPrintLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Download File Dialog -->
              <template v-if="visibleCommandDownload(item)">
                <base-menu-item @click="showDownloadFileDialog(item)">
                  <template v-slot:iconName>
                    {{ commandDownloadIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ tooltipDownloadLabel(item) }}
                    </div>
                  </template>
                  {{ commandDownloadLabel(item) }}
                </base-menu-item>
              </template>

              <!-- Show Email File Dialog - remarks: Under Development only -->
              <base-menu-item
                v-if="visibleCommandEmail(item)"
                @click="onEmailFile(item)"
              >
                <template v-slot:iconName>
                  {{ commandEmailIconName }}
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipEmailLabel(item) }}
                  </div>
                </template>
                {{ commandEmailLabel }}
              </base-menu-item>

              <!-- view record details -->
              <base-menu-item @click="showRecordDetailsDialog(item)">
                <template v-slot:icon>
                  <record-badge-icon
                    :record="item"
                    :info-only="true"
                  ></record-badge-icon>
                </template>
                <template v-slot:tooltipContent>
                  <div>
                    {{ tooltipViewRecordDetailsLabel(item) }}
                  </div>
                </template>
                {{ commandViewRecordDetailsLabel(item) }}
              </base-menu-item>

              <!-- Add more menu items here -->
            </template>
          </base-drop-down-menu-button>
        </template>
      </v-data-table>
    </template>

    <!-- snackbar -->
    <template v-slot:snackbar>
      <snackbar
        v-model="snackbarVisible"
        @close="closeSnackbar"
        :color="snackbarColor"
        :top="snackbarTop"
        >{{ snackbarText }}</snackbar
      >
    </template>
  </base-table-layout>
</template>

<script>
// mixins
import { dataTableFolderFileVersionsMixin } from "@/mixins/folder/fileVersions/dataTableFolderFileVersionsMixin";
import { annotationOptionMixin } from "@/mixins/shared/documents/annotationOptionMixin";

export default {
  name: "FolderFileVersions",
  mixins: [dataTableFolderFileVersionsMixin, annotationOptionMixin]
};
</script>
