import { fieldName, fieldLabel } from "@/model/file/fileVersionModel";

import {
  headerName,
  headerText
} from "@/model/common/dataTable/dataTableConst";

const headers = Object.freeze([
  {
    text: fieldLabel.recordType,
    value: fieldName.recordType,
    visible: true // make visible record Type icons. since can be different file types per file version
  },
  {
    text: fieldLabel.id,
    value: fieldName.id,
    visible: false
  },
  {
    text: fieldLabel.name,
    value: fieldName.name,
    visible: true
  },
  {
    text: fieldLabel.version,
    value: fieldName.version,
    visible: true
  },
  {
    text: fieldLabel.pageCount,
    value: fieldName.pageCount,
    visible: true
  },
  {
    text: fieldLabel.creationDate,
    value: fieldName.creationDate,
    visible: true
  },
  {
    text: fieldLabel.createdBy,
    value: fieldName.createdBy,
    visible: true
  },
  {
    text: fieldLabel.modificationDate,
    value: fieldName.modificationDate,
    visible: false
  },
  {
    text: fieldLabel.owner,
    value: fieldName.owner,
    visible: false
  },
  {
    text: fieldLabel.versionOwner,
    value: fieldName.versionOwner,
    visible: false
  },
  {
    text: fieldLabel.extension,
    value: fieldName.extension,
    visible: false
  },
  {
    text: fieldLabel.state,
    value: fieldName.state,
    visible: false
  },
  {
    text: fieldLabel.fileSize,
    value: fieldName.fileSize,
    visible: true
  },
  {
    text: fieldLabel.comments,
    value: fieldName.comments,
    visible: true
  },
  {
    text: headerText.actions,
    value: headerName.actions,
    sortable: false,
    align: "end",
    visible: true
  }
]);

export { headers };
