import {
  fieldName as recordFieldName,
  fieldLabel as recordFieldLabel
} from "@/model/record/recordModel";

const fieldName = Object.freeze({
  id: recordFieldName.id,
  parentId: recordFieldName.parentId,
  name: recordFieldName.name,
  categoryId: "categoryId",
  createdBy: "createdBy",
  creationDate: "creationDate",
  modificationDate: "modificationDate",
  extension: "extension",
  flags: "flags",
  hasImage: "hasImage",
  owner: "owner",
  pageCount: "pageCount",
  recordTypeId: "recordTypeId",
  recordType: "recordType",
  state: "state",
  stateId: "stateId",
  version: "version",
  isComposite: "isComposite",
  isDeleted: "isDeleted",
  isDraft: "isDraft",
  isLink: "isLink",
  isLocked: "isLocked",
  isReadOnly: "isReadOnly",
  isSingleFile: "isSingleFile",
  versionDate: "versionDate",
  versionOwner: "versionOwner",
  fileSize: "fileSize",
  canCheckOutForSigning: "canCheckOutForSigning",
  comments: "comments",
  outFileType: "outFileType"
});

const fieldLabel = Object.freeze({
  id: recordFieldLabel.id,
  name: "Name",
  category: "Category",
  createdBy: "Created By",
  creationDate: "Created On",
  modificationDate: "Modified On",
  extension: "Extension",
  owner: recordFieldLabel.owner,
  pageCount: recordFieldLabel.pageCount,
  recordTypeId: "Type Id",
  recordType: "Type",
  type: "Type",
  state: "State",
  version: "Version",
  versionDate: "Version Date",
  versionOwner: "Version Owner",
  fileSize: "File Size",
  comments: "Comments"
});

export { fieldName, fieldLabel };
